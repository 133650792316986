<template>
  <v-container pa-10>
    <v-container>
      <v-row>
        <v-col>
          <v-btn rounded color="primary" small dark to="/soaps"
            >Continue Shopping</v-btn
          >
        </v-col>
        <v-col>
          <v-btn rounded color="primary" small :disabled="!loggedin" to="/orders"
            >Order History</v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            rounded
            color="primary"
            :disabled="!gotsoap"
            small
            dark
            to="/cart"
            >Checkout</v-btn
          >
        </v-col>
        <v-col> &nbsp;</v-col>
      </v-row>
    </v-container>
    <v-container v-show="delmsg">
      <v-row>
        <v-col align-self="center">
          <p>
            <strong>{{ delmsg }}</strong>
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-show="!loggedin">
      <v-form v-model="isLocalValid">
        <v-row>
          <v-col v-show="allowGo">
            <GoogleLogin
              :params="params"
              :renderParams="renderParams"
              :onSuccess="onSuccess"
              :onFailure="onFailure"
              :onCurrentUser="onSuccess"
            ></GoogleLogin>
          </v-col>
          <v-col v-show="allowFB">
            <button class="fb-button" @click="logInWithFacebook">
              Sign in with Facebook
            </button>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            or use your own account at J.Marie with your email address.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="login_email"
              label="Email address"
              :rules="[rules.required, rules.email]"
              clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="login_password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Enter password"
              hint="At least 8 characters"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-show="login_error_msg">
          <v-col>
            <p>
              <strong>{{ login_error_msg }}</strong>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              rounded
              color="primary"
              :disabled="!isLocalValid"
              @click="emailLogin"
            >
              Login
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              rounded
              color="primary"
              :disabled="!isLocalValid"
              @click="emailLogin"
            >
              Sign Up
            </v-btn>
          </v-col>
          <v-col align="right">
            <v-btn
              rounded
              color="primary"
              :disabled="!login_email"
              @click="resetLogin"
            >
              Reset Password
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-show="!login_email">
          {{ login_msg }}
        </v-row>
      </v-form>
    </v-container>
    <v-container v-show="loggedin">
      <v-row>
        <v-col>
          <div v-show="logintype == 'google'">
            <GoogleLogin
              :params="params"
              :renderParams="renderParams"
              :onSuccess="onSuccess"
              :onFailure="onFailure"
              :onCurrentUser="onSuccess"
            ></GoogleLogin>
          </div>
          <button
            v-show="logintype == 'facebook'"
            class="fb-button"
            disabled
            @click="logInWithFacebook"
          >
            Signed in with Facebook
          </button>
          <span v-show="logintype == 'local'">
            You are logged in with {{ this.login_email }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn class="primary" small rounded @click="onLogout">
            Log out
          </v-btn>
        </v-col>
        <v-col>
          <v-btn class="primary" small rounded @click="delAccount">
            Delete My Account
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <br />
    <div v-if="account_id" class="information">
      <location-frame>
        <form
          slot-scope="{
            address,
            error,
            fetchAddress,
            geolocationSupported,
            loading,
          }"
          class="o-vertical-spacing"
        >
          <div v-if="error" class="error">
            There was an error fetching your location, please try again.
          </div>
          <v-form v-model="isFormValid">
            <v-card min-width="300" elevation="2">
              <v-card-title>Billing Address</v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="account_email"
                  label="Email address"
                  :rules="[rules.required, rules.email]"
                />
                <v-text-field
                  v-model="account_name"
                  label="Name"
                  :rules="[rules.required, rules.counter]"
                />
              </v-card-text>
              <v-card-text v-if="street != ''">
                <v-text-field v-model="street" label="Street" />
                <v-text-field v-model="town" label="Town" />
                <v-text-field v-model="state" label="State" />
                <v-text-field v-model="postcode" label="ZIP Code" />
                <v-text-field v-model="country" label="Country" />
              </v-card-text>
              <v-card-text v-if="street == ''">
                <v-text-field v-model="address.street" label="Street" />
                <v-text-field v-model="address.town" label="Town" />
                <v-text-field v-model="address.state" label="State" />
                <v-text-field v-model="address.postcode" label="ZIP Code" />
                <v-text-field v-model="address.country" label="Country" />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  v-if="geolocationSupported & (street == '')"
                  x-small
                  rounded
                  color="primary"
                  dark
                  @click="fetchAddress"
                >
                  {{ loading ? "Loading..." : "Autofill my address" }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  x-small
                  rounded
                  color="primary"
                  @click="saveAddress(address)"
                  :disabled="!isFormValid"
                >
                  Save</v-btn
                ></v-card-actions
              >
              <v-container>
                <v-row dense>
                  <v-col>
                    <v-card-text v-if="address.road"
                      >Please verify your address, especially your house number
                      <strong>{{ address.house_number }}</strong> and street
                      <strong>{{ address.road }}</strong
                      >.</v-card-text
                    >
                    <v-card-text v-if="!address.road">&nbsp;</v-card-text>
                  </v-col>
                  <v-col cols="auto" align="right">
                    <v-card-text primary
                      ><strong>{{ savemsg }}</strong></v-card-text
                    >
                  </v-col>
                </v-row></v-container
              >
            </v-card>
          </v-form>
        </form>
      </location-frame>
    </div>
  </v-container>
</template>

<style scoped>
.information {
  width: 50%;
  margin: 15px 0px;
}
.information input {
  width: 100%;
  padding: 5px;
  margin: 5px 0px;
}
.fb-button {
  color: white;
  min-width: 150px;
  background-color: #42639f;
  height: 2.5rem;
  border-radius: 2rem;
  font-weight: 400;
  font-size: 0.8rem;
}
</style>

<script>
// @ is an alias to /src
import GoogleLogin from "vue-google-login";
import LocationFrame from "../components/LocationFrame.js";
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      loggedin: false,
      logintype: "",
      login_email: "",
      login_password: "",
      login_msg:
        "If you don't have an account yet, you can enter your email address and a password to create one.",
      login_error_msg: "",
      allowGo: true,
      allowFB: false,
      isFormValid: false,
      isLocalValid: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        counter: (value) => value.length <= 200 || "Max 200 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        emailMatch: () => `The email and password you entered do not match.`,
      },
      customer: [],
      account_id: "",
      account_name: "",
      account_email: "",
      street: "",
      town: "",
      state: "",
      postcode: "",
      country: "",
      savemsg: "",
      delmsg: "",
      gotsoap: false,
      // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
      params: {
        client_id:
          "872248123544-cmkbenno59gpu5s4t0kaa1f9hs672fk5.apps.googleusercontent.com",
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
    };
  },
  props: {
    query: String,
  },
  components: {
    GoogleLogin,
    LocationFrame,
  },
  methods: {
    onFBSuccess(currtoken) {
      const path = "https://jmarie.net/api/fbtoken?id=" + currtoken;

      this.delmsg = "";

      axios
        .get(path)
        .then((res) => {
          this.loggedin = true;
          this.$store.commit("changeToken", currtoken);
          this.logintype = "facebook";
          this.$store.commit("changeType", this.logintype);
          this.customer = res.data.customer;
          this.account_id = this.customer["account_id"];
          this.$store.commit("changeAcctID", this.account_id);
          this.account_name = this.customer["account_name"];
          this.account_email = this.customer["account_email"];
          this.street = this.customer["street"];
          this.town = this.customer["town"];
          this.state = this.customer["state"];
          this.postcode = this.customer["postcode"];
          this.country = this.customer["country"];
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async logInWithFacebook() {
      //await this.loadFacebookSDK(document, "script", "facebook-jssdk");  //Loaded via index.html
      await this.initFacebook();
      let vuethis = this;
      window.FB.login(function(response) {
        if (response.authResponse) {
          vuethis.onFBSuccess(response.authResponse.accessToken);
        } else {
          alert("Login cancelled or not fully authorized.");
        }
      });
      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: "1052334585345291", //You will need to change this
          cookie: true, // This is important; it's not enabled by default
          version: "v13.0"
        });
      };
    },
    // https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0&appId=1052334585345291&autoLogAppEvents=1
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    onSuccess(googleUser) {
      // console.log(googleUser);
      let currtoken = googleUser.getAuthResponse().id_token;

      const path = "https://jmarie.net/api/token?id=" + currtoken;

      this.delmsg = "";

      axios
        .get(path)
        .then((res) => {
          this.loggedin = true;
          this.$store.commit("changeToken", currtoken);
          this.logintype = "google";
          this.$store.commit("changeType", this.logintype);
          this.customer = res.data.customer;
          this.account_id = this.customer["account_id"];
          this.$store.commit("changeAcctID", this.account_id);
          this.account_name = this.customer["account_name"];
          this.account_email = this.customer["account_email"];
          this.street = this.customer["street"];
          this.town = this.customer["town"];
          this.state = this.customer["state"];
          this.postcode = this.customer["postcode"];
          this.country = this.customer["country"];
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onFailure() {
      console.error("Unable to login with Google account");
    },
    onLogout() {
      if (this.logintype == "google") {
        window.gapi.auth2.getAuthInstance().signOut();
      }
      if (this.logintype == "facebook") {
        window.FB.logout(function() {});
      }
      this.loggedin = false;
      this.$store.commit("changeToken", "");
      this.logintype = "";
      this.$store.commit("changeType", this.logintype);
      this.account_id = "";
      this.$store.commit("changeAcctID", this.account_id);
      this.account_name = "";
      this.account_email = "";
      this.street = "";
      this.town = "";
      this.state = "";
      this.postcode = "";
      this.country = "";
    },
    emailLogin() {
      // console.log("Email is " + this.login_email);
      const path = "https://jmarie.net/api/login_email";

      this.delmsg = "";

      let bulkdata = {
        email: this.login_email,
        password: this.login_password,
      };
      axios
        .post(path, bulkdata)
        .then((res) => {
          const status = res.data.status;
          if (status == "success") {
            this.loggedin = true;
            this.customer = res.data.customer;
            this.$store.commit("changeToken", this.customer["id"]);
            this.logintype = "local";
            this.$store.commit("changeType", this.logintype);
            this.account_id = this.customer["account_id"];
            this.$store.commit("changeAcctID", this.account_id);
            this.account_name = this.customer["account_name"];
            this.account_email = this.customer["account_email"];
            this.street = this.customer["street"];
            this.town = this.customer["town"];
            this.state = this.customer["state"];
            this.postcode = this.customer["postcode"];
            this.country = this.customer["country"];
            this.login_password = "";
            this.login_error_msg = "";
          } else {
            this.login_error_msg =
              "The email and password you entered do not match.";
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    resetLogin() {
      const path = "https://jmarie.net/api/reset_email";

      let bulkdata = {
        email: this.login_email,
      };
      axios
        .post(path, bulkdata)
        .then((res) => {
          const status = res.data.status;
          if (status == "success") {
            this.login_msg = "Please check your email for a reset link.";
            this.login_email = "";
          } else {
            console.log("Login error");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fillAddress(address) {
      this.street = address.street;
      this.town = address.town;
      this.state = address.state;
      this.postcode = address.postcode;
      this.country = address.country;
      address.road = "";
      address.street = "";
    },
    saveAddress(address) {
      const path = "https://jmarie.net/api/save_cust";

      this.savemsg = "";

      if (address.street) {
        //console.log("Address:" + address.street);
        this.fillAddress(address);
      }
      let bulkdata = {
        id: this.$store.getters.currtoken,
        type: this.logintype,
        account_id: this.account_id,
        account_name: this.account_name,
        account_email: this.account_email,
        street: this.street,
        town: this.town,
        state: this.state,
        postcode: this.postcode,
        country: this.country,
      };
      axios.post(path, bulkdata).then((res) => {
        const status = res.data.status;
        if (status == "success") {
          this.savemsg = "Information successfully saved.";
          if (this.logintype == "local") {
            this.loggedin = true;
            this.customer = res.data.customer;
            this.logintype = "local";
            this.$store.commit("changeType", this.logintype);
            this.account_id = this.customer["account_id"];
            this.$store.commit("changeAcctID", this.account_id);
            this.account_name = this.customer["account_name"];
            this.account_email = this.customer["account_email"];
            this.street = this.customer["street"];
            this.town = this.customer["town"];
            this.state = this.customer["state"];
            this.postcode = this.customer["postcode"];
            this.country = this.customer["country"];
            this.login_email = this.account_id;
            this.login_password = "";
            this.login_error_msg = "";
          }
        } else {
          this.savemsg =
            "There was an error - please try again later.  Thank you.";
        }
      });
    },
    delAccount() {
      const path = "https://jmarie.net/api/del_cust";

      this.delmsg = "";

      let bulkdata = {
        id: this.$store.getters.currtoken,
        type: this.logintype,
        account_id: this.account_id,
        account_name: this.account_name,
        account_email: this.account_email,
      };
      axios.post(path, bulkdata).then((res) => {
        const status = res.data.status;
        if (status == "success") {
          this.delmsg = "Your account was successfully deleted.";
          this.onLogout();
          this.login_email = "";
          this.login_password = "";
          this.login_error_msg = "";
        } else {
          this.delmsg =
            "There was an error - please try again later.  Thank you.";
        }
      });
    },
    checkAuth() {
      let soapcount = parseInt(this.$store.getters.currcartcount);
      if (soapcount > 0) {
        this.gotsoap = true;
      }
      let currtoken = this.$store.getters.currtoken;
      if (currtoken) {
        this.logintype = this.$store.getters.currtype;
        this.account_id = this.$store.getters.curracctid;
        this.loggedin = true;
        const path = "https://jmarie.net/api/get_address";

        this.savemsg = "";

        let bulkdata = {
          id: currtoken,
          type: this.logintype,
          account_id: this.account_id,
        };
        axios
          .post(path, bulkdata)
          .then((res) => {
            this.customer = res.data.customer;
            this.account_name = this.customer["account_name"];
            this.account_email = this.customer["account_email"];
            this.street = this.customer["street"];
            this.town = this.customer["town"];
            this.state = this.customer["state"];
            this.postcode = this.customer["postcode"];
            this.country = this.customer["country"];
            if (this.logintype == "local") {
              this.login_email = this.account_email;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
  created() {
    this.checkAuth();
  },
};
</script>
