<template>
  <v-card class="mx-auto my-4" :max-width="cardwidth">
    <v-carousel cycle interval="8000" :show-arrows="false" :height="cardheight">
      <v-carousel-item
        v-for="(item, i) in imgcarousel"
        :key="i"
        reverse-transition="fade-transition"
        transition="fade-transition"
        ><v-img
          :src="item"
          contain
          :max-height="cardheight"
          :max-width="cardwidth"
        ></v-img>
      </v-carousel-item>
    </v-carousel>
    <v-container class="inputs-container">
      <v-row no-gutters class="mx-2 my-0 py-0" align="center">
        <v-col cols="auto">
          <v-card-title>{{ acc_name }}</v-card-title>
        </v-col>
        <v-col>
          <v-card-text align="right"> ${{ acc_price }} </v-card-text>
        </v-col>
      </v-row>
      <v-row no-gutters class="mx-2 my-0 py-0" align="center">
        <v-col cols="auto">
          <v-card-text
            ><strong>{{ acc_notes }}</strong></v-card-text
          >
        </v-col>
        <v-col>
          <v-card-text align="right">
            <v-btn class="primary" x-small @click="cartAdd">Add to Cart</v-btn>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <v-card-text class="body-1 text-left">
          <span v-html="acc_details"></span>
        </v-card-text>
      </v-row>
      <v-row v-show="acc_ingredients" class="mx-2">
        <v-card-text class="body-2 text-left">
          <strong>Ingredients: </strong>{{ acc_ingredients }}
        </v-card-text>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn class="ma-2" rounded color="primary" small dark to="/accessories"
            >Continue Shopping</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.inputs-container {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>

<script>
import axios from "axios";

export default {
  name: "Accessory",
  data() {
    return {
      itemcount: 0,
      acc_id: 0,
      acc_name: "",
      acc_notes: "",
      acc_details: "",
      acc_price: "",
      acc_quantity: 1,
      acc_ingredients: "",
      imgcarousel: [],
    };
  },
  computed: {
    cardwidth() {
      let defval = 960;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          defval = 360;
          break;
        case "sm":
          defval = 480;
          break;
        case "md":
          defval = 720;
          break;
      }
      return defval;
    },
    cardheight() {
      let defval = 540;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          defval = 203;
          break;
        case "sm":
          defval = 270;
          break;
        case "md":
          defval = 405;
          break;
      }
      return defval;
    },
  },
  methods: {
    cartAdd() {
      if (this.acc_quantity > this.itemcount) {
        this.itemcount = this.itemcount + 1;
        //this.$cookies.set("itemcount",this.itemcount,"1d", null, null, "jmarie.net", "Strict");
        this.$store.commit("changeCartCount", parseInt(this.itemcount));
        this.$store.commit(
          "changeCartItems",
          this.acc_name + " | 1" + " | " + this.acc_price
        );
      }
    },
    oneAccessory() {
      if (this.$route.params.acc_id) {
        this.acc_id = this.$route.params.acc_id;
        console.log("Accessory ID set to " + this.$route.params.acc_id);
      }
      console.log("acc_id: " + this.acc_id);
      const path = "https://jmarie.net/api/acc?id=" + this.acc_id;

      axios
        .get(path)
        .then((res) => {
          this.allaccs = res.data.accs[0];
          this.acc_name = this.allaccs.acc;
          this.acc_notes = this.allaccs.notes;
          this.acc_details = this.allaccs.details;
          this.acc_ingredients = this.allaccs.ingredients;
          this.acc_price = this.allaccs.price;
          this.acc_quantity = this.allaccs.quantity;
          this.imgcarousel = this.allaccs.images;

          //if ( this.$cookies.isKey("itemcount") ) {
          //  this.itemcount = parseInt(this.$cookies.get("itemcount"));
          //}
          this.itemcount = this.$store.getters.currcartcount;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.oneAccessory();
  },
};
</script>
