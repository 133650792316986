import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2E4172',
                secondary: '#413075',
                tertiary: '#226666',
                accent: '#AA8439',
                darkgrey: '#808080',
                grey: '#909090',
            }
        }
    }
});
