<template>
  <div>
    <v-container fluid pa-4>
      <v-row dense class="align-bottom">
        <v-col cols="5">
          <v-select
            v-model="sortchoice"
            :items="sortoptions"
            @input="allAccs"
            label="Sort by"
            dense
          ></v-select>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-icon @click="allAccs">
            mdi-magnify
          </v-icon>
        </v-col>
        <v-col cols="5">
          <v-text-field
            dense
            v-model="filterchoice"
            hide-details
            single-line
            clearable
            @input="allAccs"
            @keydown.enter="allAccs"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          xs="12"
          sm="6"
          md="4"
          lg="3"
          v-for="acc in allaccs"
          :key="acc.acc"
        >
          <v-card class="mx-auto my-4" :max-width="cardwidth">
            <v-carousel
              :show-arrows="false"
              :height="cardheight"
            >
              <v-carousel-item
                v-for="(item, i) in acc.images"
                :key="i"
                reverse-transition="fade-transition"
                transition="fade-transition"
                :to="{ name: 'Accessory', params: { acc_id: acc.id } }"
                ><v-img
                  :src="item"
                  contain
                  :max-height="cardheight"
                  :max-width="cardwidth"
                ></v-img>
              </v-carousel-item>
            </v-carousel>
            <v-container class="inputs-container">
              <v-row no-gutters class="mx-2 my-0 py-0" align="center">
                <v-col cols="auto">
                  <v-card-title>{{ acc.acc }}</v-card-title>
                </v-col>
                <v-col>
                  <v-card-text align="right"> ${{ acc.price }} </v-card-text>
                </v-col>
              </v-row>
              <v-row no-gutters class="mx-2 my-0 py-0" align="center">
                <v-col cols="auto">
                  <v-card-text
                    ><strong>{{ acc.notes }}</strong></v-card-text
                  >
                </v-col>
                <v-col>
                  <v-card-text align="right">
                    <v-btn class="primary" x-small @click="cartAdd(acc.acc, acc.price, acc.quantity)"
                      >Add to Cart</v-btn
                    >
                  </v-card-text>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>

<script>
import axios from "axios";

export default {
  name: "Accessories",
  data() {
    return {
      itemcount: 0,
      allaccs: [],
      sortoptions: ["Name (A-Z)", "Name (Z-A)", "Newest", "Random"],
      sortchoice: "Name (A-Z)",
      filterchoice: "",
    };
  },
  computed: {
    cardwidth() {
      return 360;
    },
    cardheight() {
      return 203;
    },
  },
  methods: {
    cartAdd(acc_name, acc_price, acc_quantity) {
      if (acc_quantity > this.itemcount) {
        this.itemcount = this.itemcount + 1;
        //this.$cookies.set("itemcount",this.itemcount,"1d", null, null, "jmarie.net", "Strict");
        this.$store.commit("changeCartCount", parseInt(this.itemcount));
        this.$store.commit(
          "changeCartItems",
          acc_name + " | 1" + " | " + acc_price
        );
      }
    },
    allAccs() {
      const path =
        "https://jmarie.net/api/accs?sort=" +
        this.sortchoice +
        "&filter=" +
        this.filterchoice;

      axios
        .get(path)
        .then((res) => {
          this.allaccs = res.data.accs;
          this.itemcount = this.$store.getters.currcartcount;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.allAccs();
  },
};
</script>
