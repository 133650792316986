import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Soaps from '../components/Soaps.vue'
import Soap from '../components/Soap.vue'
import Accessories from '../components/Accessories.vue'
import Accessory from '../components/Accessory.vue'
import Orders from '../components/Orders.vue'
import Cart from '../components/Cart.vue'
import Login from '../views/Login.vue'
import Reset from '../views/Reset.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/soaps',
    name: 'Soaps',
    component: Soaps
  },
  {
    path: '/soap/:soap_id',
    name: 'Soap',
    component: Soap
  },
  {
    path: '/accessories',
    name: 'Accessories',
    component: Accessories
  },
  {
    path: '/accessory/:acc_id',
    name: 'Accessory',
    component: Accessory
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/reset/:reset_id',
    name: 'Reset',
    component: Reset
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
