<template>
  <v-app id="jmmapp">
    <v-app-bar app color="primary" height="40" min-width="380" dark>
      <v-container fluid>
        <v-row align="center">
          <v-col cols="3" align="left">
            <v-app-bar-nav-icon @click="navdraw = !navdraw">
              <div class="d-flex align-center">
                <v-icon large color="white darken-1">mdi-menu</v-icon>
              </div>
            </v-app-bar-nav-icon>
          </v-col>
          <v-col cols="6" align="center">
            <v-img
              alt="J Marie Bath and Body"
              height="36"
              contain
              :src="jmarielogo"
            ></v-img>
          </v-col>
          <v-col cols="3" align="right">
            <v-btn plain :to="{ name: 'Cart' }">Cart ({{ cartcount }})</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      app
      v-model="navdraw"
      temporary
      height="auto"
      color="white"
    >
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }} </v-icon>
          </v-list-item-action>
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container>
        <v-row align="center">
          <v-col v-show="!big" align-self="center" class="text-center">
            <v-chip label small class="mx-3 my-2" color="secondary lighten-2" to="/">Home</v-chip>
            <v-chip label small class="mx-3 my-2" color="secondary lighten-2" to="/soaps">Soaps</v-chip>
            <v-chip label small class="mx-3 my-2" color="secondary lighten-2" to="/accessories">Extras</v-chip>
            <v-chip label small class="mx-3 my-2" color="secondary lighten-2" to="/about">About</v-chip>
          </v-col>
          <v-col v-show="big" align-self="center" class="text-center">
            <v-chip label class="mx-6 my-2" color="secondary lighten-2" to="/">Home</v-chip>
            <v-chip label class="mx-6 my-2" color="secondary lighten-2" to="/soaps">Soaps</v-chip>
            <v-chip label class="mx-6 my-2" color="secondary lighten-2" to="/accessories">Extras</v-chip>
            <v-chip label class="mx-6 my-2" color="secondary lighten-2" to="/about">About</v-chip>
          </v-col>
        </v-row>
      </v-container>
      <router-view />
    </v-main>
    <v-container my-8>
      <v-row dense align="center">
        <v-col cols="2" align-self="center">
          <a
            href="https://www.instagram.com/j.mariebathandbody"
            target="_blank"
            class="smicon"
            ><v-img
              src="/instagram_logo_icon.png"
              contain
              :max-width="iconwidth"
            ></v-img
          ></a>
        </v-col>
        <v-col cols="2" align-self="center">
          <a
            href="https://www.youtube.com/playlist?list=UUTFnlvg6dm9msneG3Jr2uMQ"
            target="_blank"
            class="smicon"
            ><v-img
              src="/youtube_logo_icon.png"
              contain
              :max-width="iconwidth"
            ></v-img
          ></a>
        </v-col>
        <v-col cols="2" align-self="center">
          <a
            href="https://www.facebook.com/jmariebathandbody/"
            class="smicon"
            target="_blank"
            ><v-img
              src="/facebook_icon.png"
              contain
              :max-width="iconwidth"
            ></v-img
          ></a>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="6" align="right">
          <a href="mailto: customer_service@jmarie.net">Contact Us</a>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<style>
.smicon:hover {
  opacity: 0.7;
}
</style>

<script>
export default {
  name: "J.Marie",

  components: {},

  data: () => ({
    navdraw: false,
    menuItems: [
      { title: "Home", path: "/", icon: "mdi-home" },
      { title: "Soaps", path: "/soaps", icon: "mdi-square-rounded-outline" },
      { title: "Accessories", path: "/accessories", icon: "mdi-basket" },
      { title: "Orders", path: "/orders", icon: "mdi-star-outline" },
      { title: "Login", path: "/login", icon: "mdi-key" },
      { title: "About", path: "/about", icon: "mdi-help" },
    ],
  }),
  computed: {
    cartcount() {
      //if ( this.$cookies.isKey("itemcount") ) {
      //  this.$store.commit("changeCartCount", parseInt(this.$cookies.get("itemcount")));
      //}
      return this.$store.getters.currcartcount;
    },
    jmarielogo() {
      let logoimg = "/jmarie_white.png";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          logoimg = "/jmarie_only.png";
          break;
        case "sm":
          logoimg = "/jmarie_only.png";
          break;
        case "md":
          logoimg = "/jmarie_only.png";
          break;
      }
      logoimg = "/jmarie_white.png";
      return logoimg;
    },
    iconwidth() {
      let defval = 36;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          defval = 24;
          break;
      }
      return defval;
    },
    big() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
      }
      return true;
    },
  },
};
</script>
