<template>
  <v-card class="mx-auto my-4" :max-width="cardwidth">
    <v-form v-model="isFormValid">
      <v-container>
        <v-row v-show="!password_msg" align="center">
          <v-col>
            <v-text-field
              v-model="login_password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Enter password"
              hint="At least 8 characters"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn rounded color="primary" small :disabled="!isFormValid" @click="resetPassword()"
              >Reset Password</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-show="password_msg">
          <v-col align="center">
            <p>{{ password_msg }}</p>
          </v-col>
        </v-row>
        <v-row v-show="loggedin">
          <v-col>
            <v-btn rounded color="primary" small dark to="/soaps"
              >Continue Shopping</v-btn
            >
          </v-col>
          <v-col>
            <v-btn rounded color="primary" small dark to="/orders"
              >Order History</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              rounded
              color="primary"
              :disabled="!gotsoap"
              small
              dark
              to="/cart"
              >Checkout</v-btn
            >
          </v-col>
          <v-col>
            &nbsp;
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "Reset",
  data() {
    return {
      loggedin: false,
      logintype: "",
      customer: [],
      account_id: "",
      reset_id: "",
      password_msg: "",
      login_password: "",
      isFormValid: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        counter: (value) => value.length <= 200 || "Max 200 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  methods: {
    checkAuth() {
      if (this.$route.params.reset_id) {
        this.reset_id = this.$route.params.reset_id;
      } else {
        this.loggedin = false;
        this.$store.commit("changeToken", "");
        this.$router.push({
          name: "Home",
        });
      }
    },
    resetPassword() {
      const path = "https://jmarie.net/api/reset_password";

      let bulkdata = {
        id: this.reset_id,
        password: this.login_password,
      };
      axios
        .post(path, bulkdata)
        .then((res) => {
          if (res.data.status != "success") {
            this.loggedin = false;
            this.$store.commit("changeToken", "");
            this.$router.push({
              name: "Login",
            });
          } else {
            this.password_msg = "Your password has been successfully reset.";
            this.loggedin = true;
            this.customer = res.data.customer;
            this.$store.commit("changeToken", this.customer["id"]);
            this.logintype = "local";
            this.$store.commit("changeType", this.logintype);
            this.account_id = this.customer["account_id"];
            this.$store.commit("changeAcctID", this.account_id);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.checkAuth();
  },
};
</script>
