<template>
  <div>
    <Soap/>
  </div>
</template>

<script>
// @ is an alias to /src
import Soap from '@/components/Soap.vue'

export default {
  name: 'Home',
  components: {
    Soap
  }
}
</script>
