<template>
  <div class="ma-4">
    <p class="text-center">The page for which you are looking appears to have been washed away.</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NotFound',
  components: {}
}
</script>