import { render, staticRenderFns } from "./Soap.vue?vue&type=template&id=1c2c7f29&scoped=true"
import script from "./Soap.vue?vue&type=script&lang=js"
export * from "./Soap.vue?vue&type=script&lang=js"
import style0 from "./Soap.vue?vue&type=style&index=0&id=1c2c7f29&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c2c7f29",
  null
  
)

export default component.exports