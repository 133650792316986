<template>
  <div>
    <v-card class="mx-auto my-6" max-width="800">
      <v-btn class="ma-2" rounded color="primary" small dark to="/soaps"
        >Continue Shopping</v-btn
      >
      <v-form v-show="gotsoap" v-model="isFormValid">
        <v-card-title>Shopping Cart</v-card-title>
        <v-container v-show="!loggedin">
          <v-row dense class="ma-2" align="center">
            <v-col align="left" cols="2">
              <v-btn
                rounded
                color="primary"
                :disabled="loggedin"
                small
                dark
                to="/login"
                >Login</v-btn
              >
            </v-col>
            <v-col>
              <v-card-text small
                >If purchasing as a guest, please enter the shipping
                information. Note that Order History is not viewable as a
                guest.</v-card-text
              >
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid>
          <v-row dense class="pa-2">
            <v-card elevation="1" :min-width="cardwidth" max-width="800">
              <v-card-title>Shipping Address</v-card-title>
              <v-card-text>
                <v-text-field
                  class="px-2 py-0"
                  v-model="account_email"
                  label="Email address"
                  :rules="[rules.required, rules.email]"
                />
                <v-text-field
                  class="px-2 py-0"
                  v-model="account_name"
                  label="Name"
                  :rules="[rules.required, rules.counter]"
                />
                <v-text-field
                  class="px-2 py-0"
                  v-model="street"
                  label="Street"
                  :rules="[rules.required, rules.counter]"
                />
                <v-text-field
                  class="px-2 py-0"
                  v-model="town"
                  label="Town"
                  :rules="[rules.required, rules.counter]"
                />
                <v-text-field
                  class="px-2 py-0"
                  v-model="state"
                  label="State"
                  :rules="[rules.required, rules.counter]"
                />
                <v-text-field
                  class="px-2 py-0"
                  v-model="postcode"
                  label="Postal Code"
                  :rules="[rules.required, rules.counter]"
                />
                <v-text-field
                  class="px-2 py-0"
                  v-model="country"
                  label="Country"
                  :rules="[rules.required, rules.counter]"
                />
                <span v-show="notUS"
                  >We can only ship to the Continental US, Alaska, and
                  Hawaii.</span
                >
              </v-card-text>
            </v-card>
          </v-row>
          <v-row dense class="pa-1">
            <v-col>
              <v-checkbox
                v-model="newsletter"
                label="Sign me up for the J.Marie newsletter!"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid v-for="(item, i) in allitems" :key="i">
          <v-row dense class="ma-2" align="center">
            <v-col md="1">
              <v-img :src="calcimagename(item)" contain max-width="50"></v-img>
            </v-col>
            <v-col cols="auto" align-self="center">
              <strong>{{ calcname(item) }}</strong>
              <br />
              Qty: {{ calcquantity(item) }}
              <v-btn
                class="mx-2"
                fab
                dark
                color="primary"
                x-small
                @click="addOne(item)"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab
                dark
                color="primary"
                x-small
                @click="subtractOne(item)"
              >
                <v-icon dark>
                  mdi-minus
                </v-icon>
              </v-btn>
            </v-col>
            <v-col align="right">${{ calcprice(item) }} </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row dense class="ma-2">
            <v-col align="right">Total: ${{ calctotalprice() }} </v-col>
          </v-row>
          <v-row dense class="ma-2">
            <v-col>{{ shipping_msg() }}</v-col>
            <v-col align="right">Shipping: ${{ calcshipping() }} </v-col>
          </v-row>
          <v-row dense class="ma-2">
            <v-col
              ><v-btn
                class="mx-2"
                dark
                color="primary"
                x-small
                @click="msgdiag = true"
              >
                Add a gift message
              </v-btn></v-col
            >
            <v-col align="right">Tax (MA): ${{ calctax() }} </v-col>
          </v-row>
          <v-row align="center" class="ma-2">
            <v-col>
              <div id="payment-status-container">
                <strong>{{ paymentmsg }}</strong>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="ma-2">
            <v-col align="right">
              <form id="payment-form">
                <div id="card-container"></div>
                <span v-show="!isFormValid"
                  ><strong
                    >Please enter the missing shipping information.</strong
                  >&nbsp;&nbsp;&nbsp;</span
                >
                <v-btn
                  class="primary"
                  id="card-button"
                  type="button"
                  :disabled="!isFormValid || notUS"
                >
                  Pay ${{ calcpayamount() }} with Credit Card
                </v-btn>
                <br />
                <br />
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        class="px-2 py-0"
                        v-model="givenName"
                        label="First Name"
                        :rules="[rules.counter]"
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        class="px-2 py-0"
                        v-model="familyName"
                        label="Last Name"
                        :rules="[rules.counter]"
                      />
                    </v-col>
                    <v-col>
                      <v-btn
                        class="primary"
                        id="ach-button"
                        type="button"
                        :disabled="
                          !isFormValid || !givenName || !familyName || notUS
                        "
                        >Pay ${{ calcpayamount() }} with Bank Account</v-btn
                      >
                    </v-col>
                  </v-row></v-container
                >
              </form>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
    <v-dialog
      v-model="msgdiag"
      persistent
      max-width="480"
      @keydown.esc="msgdiag = false"
    >
      <v-card>
        <v-card-title>Gift Message</v-card-title>
        <v-textarea
          v-model="custommsg"
          class="pa-3"
          outlined
          auto-grow
          rows="3"
          label="What would you like to say?"
        >
        </v-textarea>
        <v-card-actions>
          <v-btn dark color="grey" @click="addMsg(0)">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn dark color="primary" @click="addMsg(1)">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
const appId = "sq0idp-MxbMwq7OH11AJxOAxwN71g";
const locationId = "DHEVXFDXWPSNB";
//const appId = "sandbox-sq0idb-pQ_KpCYte9f0XWccFt2DTA";
//const locationId = "LV3869KPH4Y4T";

export default {
  name: "Cart",
  data() {
    return {
      loggedin: false,
      logintype: "",
      account_id: "",
      msgdiag: false,
      custommsg: "",
      isFormValid: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        counter: (value) => value.length <= 200 || "Max 200 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        emailMatch: () => `The email and password you entered don't match`,
      },
      allitems: [],
      account_name: "",
      account_email: "",
      street: "",
      town: "",
      state: "",
      postcode: "",
      country: "",
      gotsoap: false,
      paymentmsg: "",
      givenName: "",
      familyName: "",
      newsletter: false,
    };
  },
  async mounted() {
    if (!window.Square) {
      throw new Error("Square.js failed to load properly");
    }
    const payments = window.Square.payments(appId, locationId);
    let card;
    try {
      card = await initializeCard(payments);
    } catch (e) {
      console.error("Initializing Card failed", e);
      return;
    }

    const cardButton = document.getElementById("card-button");
    cardButton.addEventListener("click", async function(event) {
      await handlePaymentMethodSubmission(event, card);
    });

    let ach;
    try {
      ach = await initializeACH(payments);
    } catch (e) {
      console.error("Initializing ACH failed", e);
      return;
    }

    const achButton = document.getElementById("ach-button");
    achButton.addEventListener("click", async function(event) {
      const achOptions = getACHOptions();
      await handlePaymentMethodSubmission(event, ach, achOptions); // tokenize
      // ACH with the `accountHolderName` as an option.
    });

    let vuethis = this;

    async function initializeCard(payments) {
      const card = await payments.card();
      await card.attach("#card-container");
      return card;
    }

    async function initializeACH(payments) {
      const ach = await payments.ach();
      // Note: ACH does not have an .attach(...) method
      // the ACH auth flow is triggered by .tokenize(...)
      return ach;
    }

    // Call this function to send a payment token, buyer name, and other details
    // to the project server code so that a payment can be created with
    // Payments API
    async function createPayment(token) {
      const body = JSON.stringify({
        locationId,
        sourceId: token,
        amount: parseInt(vuethis.calcpayamount() * 100),
        id: vuethis.$store.getters.currtoken,
        type: vuethis.logintype,
        account_id: vuethis.account_id,
        items: vuethis.allitems,
        shipping_name: vuethis.account_name,
        shipping_email: vuethis.account_email,
        shipping_street: vuethis.street,
        shipping_town: vuethis.town,
        shipping_state: vuethis.state,
        shipping_postcode: vuethis.postcode,
        shipping_country: vuethis.country,
        customer_message: vuethis.custommsg,
        newsletter: vuethis.newsletter,
      });
      const paymentResponse = await fetch("https://jmarie.net/api/payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });
      const order_status = await paymentResponse.json();
      console.log("Status:" + order_status["status"]);
      return order_status["status"];
    }

    // This function tokenizes a payment method.
    // The ‘error’ thrown from this async function denotes a failed tokenization,
    // which is due to buyer error (such as an expired card). It is up to the
    // developer to handle the error and provide the buyer the chance to fix
    // their mistakes.
    async function tokenize(paymentMethod, options = {}) {
      const tokenResult = await paymentMethod.tokenize(options);
      if (tokenResult.status === "OK") {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }
        throw new Error(errorMessage);
      }
    }

    function getACHOptions() {
      let accountHolderName = vuethis.givenName + " " + vuethis.familyName;
      return { accountHolderName };
    }

    // Helper method for displaying the Payment Status on the screen.
    // status is either SUCCESS or FAILURE;
    function displayPaymentResults(status) {
      const statusContainer = document.getElementById(
        "payment-status-container"
      );
      if (status === "SUCCESS") {
        vuethis.paymentmsg = "Success.  Your order has been processed.";
        vuethis.$router.push({
          name: "Orders",
          params: { paystate: "Thank you for your order!" },
        });
      } else {
        if (status === "FAILURE") {
          vuethis.paymentmsg =
            "We're sorry; there was an error processing your payment.";
        } else {
          if (status.substring(0, 4) === "soap") {
            vuethis.paymentmsg =
              "Insufficient stock - please reduce your order of " + status;
          } else {
            vuethis.paymentmsg = "We're sorry; there was an unknown error.";
          }
        }
      }
      statusContainer.style.visibility = "visible";
    }

    async function handlePaymentMethodSubmission(
      event,
      paymentMethod,
      options
    ) {
      event.preventDefault();
      try {
        // disable the submit button as we await tokenization and make a
        // payment request.
        cardButton.disabled = true;
        achButton.disabled = true;
        const token = await tokenize(paymentMethod, options);
        const paymentResults = await createPayment(token);
        if (paymentResults == "success") {
          displayPaymentResults("SUCCESS");
          return;
        }
        if (paymentResults != "success") {
          displayPaymentResults(paymentResults);
          cardButton.disabled = false;
          achButton.disabled = false;
          return;
        }
      } catch (e) {
        //console.error(e.message);
      }
      cardButton.disabled = false;
      achButton.disabled = false;
      displayPaymentResults("FAILURE");
    }
  },
  computed: {
    cardwidth() {
      let defval = 500;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          defval = 200;
          break;
        case "sm":
          defval = 300;
          break;
        case "md":
          defval = 400;
          break;
      }
      return defval;
    },
    notUS() {
      let country_lc = this.country.toLowerCase();
      if (
        country_lc == "us" ||
        country_lc == "usa" ||
        country_lc == "united states"
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    addOne(item) {
      let cartamt = parseInt(this.$store.getters.currcartcount);
      let justname = item.split(" | ")[0];
      let justprice = parseInt(item.split(" | ")[2]);
      let newquantity = cartamt + 1;
      //this.$cookies.set("itemcount",this.itemcount,"1d", null, null, "jmarie.net", "Strict");
      this.$store.commit("changeCartCount", parseInt(newquantity));
      this.$store.commit(
        "changeCartItems",
        justname + " | 1" + " | " + justprice
      );
      this.$forceUpdate();
    },
    subtractOne(item) {
      let cartamt = parseInt(this.$store.getters.currcartcount);
      let justname = item.split(" | ")[0];
      let justprice = parseInt(item.split(" | ")[2]);
      let newquantity = cartamt - 1;
      if (newquantity <= 0) {
        newquantity = 0;
        this.gotsoap = false;
      }
      //this.$cookies.set("itemcount",this.itemcount,"1d", null, null, "jmarie.net", "Strict");
      this.$store.commit("changeCartCount", parseInt(newquantity));
      this.$store.commit(
        "changeCartItems",
        justname + " | -1" + " | " + justprice
      );
      this.$forceUpdate();
    },
    addMsg(tflag) {
      if (tflag == 0) {
        this.custommsg = "";
      }
      this.msgdiag = false;
    },
    calcimagename(item) {
      let nospacesname = item
        .split(" | ")[0]
        .split(" ")
        .join("_");
      return "https://jmarie.net/static/" + nospacesname + "-1";
    },
    calcname(item) {
      let justname = item.split(" | ")[0];
      return justname;
    },
    calcquantity(item) {
      let justquantity = item.split(" | ")[1];
      return justquantity;
    },
    calcprice(item) {
      let justquantity = parseInt(item.split(" | ")[1]);
      let justprice = parseInt(item.split(" | ")[2]);
      return justquantity * justprice;
    },
    calctax() {
      // If not MA then doesn't match MA, Mass, Massachusetts but also check it is not MAIne or MARyland
      if (
        this.state.substring(0, 2).toUpperCase() != "MA" ||
        this.state.substring(0, 3).toUpperCase() == "MAI" ||
        this.state.substring(0, 3).toUpperCase() == "MAR"
      ) {
        return 0;
      }
      let total = this.calctotalprice();
      let taxamount = total * 0.0625;
      return taxamount.toFixed(2);
    },
    calcshipping() {
      if (this.postcode == "01945") {
        return 0;
      }
      let currprice = this.calctotalprice();
      if (currprice == 0) {
        return 0;
      }
      let totalshipping = 5;
      if (currprice >= 45) {
        totalshipping = 0;
      }
      return totalshipping;
    },
    shipping_msg() {
      let msg = "Free Shipping on $45 or more";
      let amtleft = 45 - this.calctotalprice();
      if (this.postcode == "01945" || amtleft <= 0) {
        msg = "You've got free shipping!";
      } else {
        if (amtleft < 45) {
          msg = "Add $" + amtleft + " for free shipping";
        }
      }
      return msg;
    },
    calctotalprice() {
      let totalprice = 0;
      this.allitems.forEach((element) => {
        let justquantity = parseInt(element.split(" | ")[1]);
        let justprice = parseInt(element.split(" | ")[2]);
        let price = justquantity * justprice;
        totalprice = totalprice + price;
      });
      return totalprice;
    },
    calcpayamount() {
      let total = parseInt(this.calctotalprice());
      let shipping = parseInt(this.calcshipping());
      let tax = parseFloat(this.calctax());
      let finalamt = total + shipping + tax;
      return finalamt.toFixed(2);
    },
    checkAuth() {
      let soapcount = parseInt(this.$store.getters.currcartcount);
      if (soapcount > 0) {
        this.gotsoap = true;
      } else {
        this.gotsoap = false;
      }
      let currtoken = this.$store.getters.currtoken;
      if (currtoken) {
        this.logintype = this.$store.getters.currtype;
        this.account_id = this.$store.getters.curracctid;
        this.loggedin = true;
        const path = "https://jmarie.net/api/get_address";

        this.savemsg = "";

        let bulkdata = {
          id: currtoken,
          type: this.logintype,
          account_id: this.account_id,
        };
        axios
          .post(path, bulkdata)
          .then((res) => {
            if (res.data.status == "token failure") {
              this.loggedin = false;
              this.$store.commit("changeToken", "");
            } else {
              this.customer = res.data.customer;
              this.account_name = this.customer["account_name"];
              this.account_email = this.customer["account_email"];
              this.street = this.customer["street"];
              this.town = this.customer["town"];
              this.state = this.customer["state"];
              this.postcode = this.customer["postcode"];
              this.country = this.customer["country"];
              const newsltr = this.customer["newsletter"]
              this.newsletter = false;
              if (newsltr.toString() == "1") {
                this.newsletter = true;
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
  created() {
    this.allitems = this.$store.getters.cartitems;
    this.checkAuth();
  },
};
</script>
