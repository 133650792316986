<template>
  <v-card class="mx-auto my-4" :max-width="cardwidth">
    <v-carousel cycle interval="8000" :show-arrows="false" :height="cardheight">
      <v-carousel-item
        v-for="(item, i) in imgcarousel"
        :key="i"
        reverse-transition="fade-transition"
        transition="fade-transition"
        ><v-img
          :src="item"
          contain
          :max-height="cardheight"
          :max-width="cardwidth"
        ></v-img>
      </v-carousel-item>
    </v-carousel>
    <v-container class="inputs-container">
      <v-row no-gutters class="mx-2 my-0 py-0" align="center">
        <v-col cols="auto">
          <v-card-title>{{ soap_name }}</v-card-title>
        </v-col>
        <v-col>
          <span v-show="soap_sale == '0'">
            <v-card-text align="right"> ${{ soap_price }} </v-card-text>
          </span>
          <span v-show="soap_sale != '0'">
            <v-card-text align="right"> <s>${{ soap_sale }}</s>&nbsp;${{ soap_price }} </v-card-text>
          </span>
        </v-col>
      </v-row>
      <v-row no-gutters class="mx-2 my-0 py-0" align="center">
        <v-col cols="auto">
          <v-card-text
            ><strong>{{ soap_notes }}</strong></v-card-text
          >
        </v-col>
        <v-col>
          <v-card-text align="right">
            <v-btn class="primary" x-small @click="cartAdd">Add to Cart</v-btn>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <v-card-text class="body-1 text-left">
          <span v-html="soap_details"></span>
        </v-card-text>
      </v-row>
      <v-row class="mx-2">
        <v-card-text class="body-2 text-left">
          <strong>Ingredients: </strong>{{ soap_ingredients }}
        </v-card-text>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn class="ma-2" rounded color="primary" small dark to="/soaps"
            >Continue Shopping</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.inputs-container {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>

<script>
import axios from "axios";

export default {
  name: "Soap",
  data() {
    return {
      itemcount: 0,
      soap_id: 0,
      soap_name: "",
      soap_notes: "",
      soap_details: "",
      soap_price: "",
      soap_sale: "",
      soap_quantity: 1,
      soap_ingredients: "",
      imgcarousel: [],
    };
  },
  computed: {
    cardwidth() {
      let defval = 960;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          defval = 360;
          break;
        case "sm":
          defval = 480;
          break;
        case "md":
          defval = 720;
          break;
      }
      return defval;
    },
    cardheight() {
      let defval = 540;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          defval = 203;
          break;
        case "sm":
          defval = 270;
          break;
        case "md":
          defval = 405;
          break;
      }
      return defval;
    },
  },
  methods: {
    cartAdd() {
      if (this.soap_quantity > this.itemcount) {
        this.itemcount = this.itemcount + 1;
        //this.$cookies.set("itemcount",this.itemcount,"1d", null, null, "jmarie.net", "Strict");
        this.$store.commit("changeCartCount", parseInt(this.itemcount));
        this.$store.commit(
          "changeCartItems",
          this.soap_name + " | 1" + " | " + this.soap_price
        );
      }
    },
    oneSoap() {
      if (this.$route.params.soap_id) {
        this.soap_id = this.$route.params.soap_id;
        console.log("Soap ID set to " + this.$route.params.soap_id);
      }
      console.log("soap_id: " + this.soap_id);
      const path = "https://jmarie.net/api/soap?id=" + this.soap_id;

      axios
        .get(path)
        .then((res) => {
          this.allsoaps = res.data.soaps[0];
          this.soap_name = this.allsoaps.soap;
          this.soap_notes = this.allsoaps.notes;
          this.soap_details = this.allsoaps.details;
          this.soap_ingredients = this.allsoaps.ingredients;
          this.soap_price = this.allsoaps.price;
          this.soap_sale = this.allsoaps.sale;
          this.soap_quantity = this.allsoaps.quantity;
          this.imgcarousel = this.allsoaps.images;

          //if ( this.$cookies.isKey("itemcount") ) {
          //  this.itemcount = parseInt(this.$cookies.get("itemcount"));
          //}
          this.itemcount = this.$store.getters.currcartcount;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.oneSoap();
  },
};
</script>
