import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'
 
Vue.use(Vuex);
 
export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        cartcount: 0,
        cartitems: [],
        currtoken: "",
        curracctid: ""
    },
    getters: {
        // .split(' ')[0]
        currtoken: state => {
            return state.currtoken
        },
        currtype: state => {
            return state.currtype
        },
        curracctid: state => {
            return state.curracctid
        },
        currcartcount: state => {
            return state.cartcount
        },
        cartitems: state => {
            return state.cartitems
        }
    },
    mutations: {
        changeToken (state, payload) {
            state.currtoken = payload
        },
        changeType (state, payload) {
            state.currtype = payload
        },
        changeAcctID (state, payload) {
            state.curracctid = payload
        },
        changeCartCount (state, payload) {
            state.cartcount = payload
        },
        changeCartItems (state, payload) {
            // See if the item already exists
            let arrindex = 0
            let currval = 0
            let newone = true
            state.cartitems.forEach(element => {
                if ( element.split(' | ')[0] == payload.split(' | ')[0]) {
                    newone = false
                    let price = parseInt(element.split(' | ')[2])
                    // Add to number instead of duplicating
                    currval = parseInt(element.split(' | ')[1])
                    let changeamt = parseInt(payload.split(' | ')[1])
                    currval = currval + changeamt
                    if (currval < 1) {
                        state.cartitems.splice(arrindex, 1);
                    } else {
                        state.cartitems[arrindex] = payload.split(' | ')[0] + " | " + currval + " | " + price
                    }
                }
                arrindex = arrindex + 1
            });
            // Add item if it wasn't found
            if (newone) {
                state.cartitems.push(payload)
            }
        },
        delCartItems (state) {
            state.cartitems = []
            state.cartcount = 0
        }
    },
    actions: {}
});