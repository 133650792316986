<template>
  <div>
    <v-card class="mx-auto my-6" max-width="850">
      <v-container>
        <v-row class="mx-2" align="center">
          <v-col>
            <strong>{{ this.ordermsg() }}</strong
            >&nbsp;
          </v-col>
          <v-col align="right">
            <v-btn class="ma-2" rounded color="primary" small dark to="/soaps"
              >Continue Shopping</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="mx-2">
          <v-card-title>Order History</v-card-title>
        </v-row>
        <v-row v-show="!loggedin" dense class="ma-2" align="center">
          <v-col>
            <p>Please login to view your order history.</p>
          </v-col>
          <v-col align="left" cols="2">
            <v-btn
              rounded
              color="primary"
              :disabled="loggedin"
              small
              dark
              to="/login"
              >Login</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-container v-for="(item, i) in allitems" :key="i">
        <v-row dense class="ma-2" align="center">
          <v-col md="1">
            <v-img :src="calcimagename(item)" contain max-width="50"></v-img>
          </v-col>
          <v-col cols="auto" align-self="center">
            <strong>{{ calcname(item) }}</strong>
            <br />
            {{ calcquantity(item) }}
          </v-col>
          <v-col v-show="calcprice(item)" align="right"
            >${{ calcprice(item) }}
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>

<script>
import axios from "axios";

export default {
  name: "Orders",
  data() {
    return {
      justdidorder:  false,
      loggedin: false,
      logintype: "",
      account_id: "",
      allitems: [],
    };
  },
  computed: {},
  props: {
    paystate: {
      type: String,
    },
  },
  methods: {
    ordermsg() {
      let omsg = this.$route.params.paystate;
      return omsg;
    },
    clearCart() {
      let omsg = this.$route.params.paystate;
      if (omsg) {
        this.$store.commit("delCartItems");
        this.justdidorder = true;
      }
    },
    calcimagename(item) {
      let nospacesname = item
        .split(" | ")[0]
        .split(" ")
        .join("_");
      if (nospacesname.substring(0, 7) == "Order_#") {
        return "https://jmarie.net/static/order";
      }
      return "https://jmarie.net/static/" + nospacesname + "-1";
    },
    calcname(item) {
      let justname = item.split(" | ")[0];
      return justname;
    },
    calcquantity(item) {
      let justquantity = item.split(" | ")[1];
      if (justquantity == "0") {
        return "";
      }
      return "Qty: " + justquantity;
    },
    calcprice(item) {
      let justname = item.split(" | ")[0];
      let justquantity = parseInt(item.split(" | ")[1]);
      let justprice = parseInt(item.split(" | ")[2]);
      if (justname.substring(0, 7) == "Order #") {
        return (justprice / 100).toFixed(2);
      }
      return justquantity * justprice;
    },
    checkAuth() {
      let currtoken = this.$store.getters.currtoken;
      if (currtoken) {
        this.logintype = this.$store.getters.currtype;
        this.account_id = this.$store.getters.curracctid;
        this.loggedin = true;
        const path = "https://jmarie.net/api/order_history";

        let bulkdata = {
          id: currtoken,
          type: this.logintype,
          account_id: this.account_id,
        };
        axios
          .post(path, bulkdata)
          .then((res) => {
            if (res.data.status == "token failure") {
              this.loggedin = false;
              this.$store.commit("changeToken", "");
              this.$router.push({
                name: "Login",
              });
            } else {
              let allorders = res.data.orders;
              this.allitems = allorders.split(",");
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        if (this.justdidorder) {
          this.justdidorder = false;
        } else {
          this.$router.push({
          name: "Login",
        });
        }
      }
    },
  },
  created() {
    this.clearCart();
    this.checkAuth();
  },
};
</script>
